.mobileHide {
  display: block;
}
.table-responsive {
  overflow-x: scroll;
  overflow-y: visible;
  height: 59vh;
}

.footer {
  padding: 1.5rem 0;
}

@media (max-width: 768px) {
  .mobileHide {
    display: none;
  }
}

.ant-select-multiple .ant-select-selector {
  border: none !important;
  padding: 1% 0;
  border-radius: 0.375rem !important;
}
.ant-select-multiple .ant-select-selection-placeholder {
  color: #172b4d !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border: none;
}
.Box-Shadow {
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02) !important;
}
